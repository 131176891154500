<template>
  <div>
    <a href="javascript:void(0);" @click="checkToControl();" data-type="pause_button">
      <!-- <i title="Scheduled" class="fas fa-play fs16 iconGreen" aria-hidden="true"></i> -->
      <v-icon v-if="row.n_control == 0" color="red" title="Pause" >mdi-pause</v-icon>

      <!-- <i title="Stopped" class="fas fa-stop fs16 iconRed" aria-hidden="true"></i> -->
      <v-icon v-else color="green" title="Start">mdi-play</v-icon>
    </a>
    <confirmationDialog
      :value="showConfirmationDialog"
      title="Notice"
      :message="message"
      :key="showConfirmationDialog"
      @confirmation-ok="sendControlUpdate(...arguments)"
      maxWidth="290"
      buttonsIDPrefix="schedule"
    ></confirmationDialog>
  </div>
</template>
<script>
import confirmationDialog from "@/commonComponents/confirmationDialog.vue";

export default {
  data() {
    return { showConfirmationDialog: false, message: "", openDialog: false };
  },
  props: ["result", "value", "row", "valueKey", "currentTable", "rowindex"],
  components: { confirmationDialog },

  methods: {
    checkToControl() {
      // {assign var="status" value="0"}
      // {if $row.n_control eq 0}
      // {assign var="status" value="1"}
      // {/if}
      this.message = "Are you sure to continue this testcase as scheduled?";
      if (this.row.n_control == 0) {
        this.message = "Are you sure to pause this testcase as scheduled?";
      }
      this.showConfirmationDialog = true;
      // $(".confirmationDialog").html(message);
      // $(".confirmationDialog").dialog({
      //   autoOpen: false,
      //   modal: true,
      //   buttons: {
      //     Yes: function () {
      //       sendControlUpdate(n_id, n_id_key, caller, status);
      //       $(this).dialog("close");
      //     },
      //     No: function () {
      //       $(this).dialog("close");
      //     },
      //   },
      // });

      // $(".confirmationDialog").dialog("open");
    },

    sendControlUpdate(check) {
      this.showConfirmationDialog = false;
      if (check) {
        let status = 0;
        if (this.row.n_control == 0) {
          status = 1;
        }
        let data = {
          function: "sendControlUpdate",
          n_id: this.value,
          n_id_key: this.row.n_id_key,
          status: status,
        };
        let caller = this;
        this.frameworkAxiosRequest({
          method: 'post',
          url: this.currentTable.tableUrl,
          data: data,
          //headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" }
        })
            .then(function (response) {
            console.log(response);
            caller.updateTable(response.data.result.table, "dialogRef");
            // caller.localLoadingDialog = false;
            // caller.showContentDialog = true;
            // caller.$emit("loading-dialog", false);
            // //handle success
            // console.log(caller.dialogRef);

            // //caller.$store.commit("pagePostChanged", response);
            // caller.dialogResponse = response;

            // caller.$set(
            //   caller.dialogResponse.data.result,
            //   "dialogRef",
            //   caller.dialogRef
            // );
          })
          .catch(function (response) {
            //handle error
            console.log(response);
          });

        // $.ajax({
        //   type: "POST",
        //   data: {
        //     function: "sendControlUpdate",
        //     n_id: n_id,
        //     n_id_key: n_id_key,
        //     status: status,
        //     requestType: "ajax",
        //   },
        //   dataType: "json",
        //   success: function (data, textStatus) {
        //     var $tableSelector = $("#dataTable");
        //     let dataTable = $tableSelector.data("dataTable");
        //     updateTableWithValuetBody(dataTable, data);
        //   },
        //   error: function (jqXHR, textStatus, errorThrown) {
        //     $dialog = getActiveDialogDiv(false);
        //     $dialog.html(jqXHR.responseText);
        //     var dialogOptions = { modal: true, width: 500 };
        //     generateDialog($dialog, dialogOptions, "");
        //   },
        // });
      }
    },
  },
};
</script>